<template>
    <video
        ref="videoDom"
        class="lazy"
        :autoplay="autoplay"
        loop
        muted
        preload="metadata"
        playsinline="true"
        webkit-playsinline="true"
        x5-video-orientation="landscape"
        disablepictureinpicture="true"
        x-webkit-airplay="allow"
        controlslist="nodownload nofullscreen noremoteplayback"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        :data-poster="poster"
    >
        <source v-for="(item, index) in sources" :key="index" :data-src="item.src" :type="item.type" />
    </video>
</template>

<script lang="ts" setup>
    import { Ref, ref, onMounted, onUnmounted } from 'vue';
    // import videojs from 'video.js';
    // import Player from 'video.js/dist/types/player';
    // import 'video.js/dist/video-js.css';

    const videoDom = ref<Element | null>(null);

    const props = withDefaults(
        defineProps<{
            sources: Array<{
                src: string;
                type: 'video/mp4';
            }>;
            poster?: string;
            autoplay?: boolean;
        }>(),
        {
            autoplay: true,
        },
    );

    function onWeixinJSBridgeReady() {
        const video = videoDom.value as HTMLVideoElement;
        window.WeixinJSBridge.invoke('getNetworkType', {}, (res) => {
            if (videoDom.value) video.play();
        });
    }

    function onVisibilitychange() {
        const video = videoDom.value as HTMLVideoElement;
        if (document.hidden) {
            video.pause();
        } else {
            setTimeout(() => {
                video.play();
            }, 2000);
        }
    }

    function onFirstScreenAutoPlay() {
        const video = videoDom.value as HTMLVideoElement;
        if (video.paused) video.play();
    }

    onMounted(() => {
        const video = videoDom.value;
        if (window.WeixinJSBridge) {
            window.WeixinJSBridge.invoke('getNetworkType', {}, (res) => {
                if (video) (video as HTMLVideoElement).play();
            });
        } else {
            document.addEventListener('WeixinJSBridgeReady', onWeixinJSBridgeReady, false);
        }
        document.addEventListener('visibilitychange', onVisibilitychange, false);

        // 安卓端微信 首屏视频自动播放
        if (!document.hidden) {
            document.addEventListener('touchstart', onFirstScreenAutoPlay, false);
        }
    });

    onUnmounted(() => {
        document.removeEventListener('WeixinJSBridgeReady', onWeixinJSBridgeReady);
        document.removeEventListener('visibilitychange', onVisibilitychange);
        document.removeEventListener('touchstart', onFirstScreenAutoPlay, false);
    });

    // todo 安卓自动播放 https://developers.weixin.qq.com/community/develop/doc/000e640d77cfa001132a6cb8456c01
    // const player: Ref<Player | null> = ref(null);
    // const options = {
    //     muted: true,
    //     autoplay: 'any',
    //     loop: true,
    //     preload: 'metadata',
    // };

    // onMounted(() => {
    //     player.value = videojs(
    //         (videoDom as Ref<Element>).value,
    //         { ...options, ...props.sources },
    //         function onPlayerReady() {
    //             videojs.log('onPlayerReady', this);

    //             const tryPlayVideo = () => {
    //                 this.play()
    //                     .then((res) => {
    //                         videojs.log('play successfully', res);
    //                         document.removeEventListener('touchstart', tryPlayVideo, false);
    //                     })
    //                     .catch((err) => {
    //                         videojs.log('play failed', err);
    //                     });
    //             };

    //             document.addEventListener('touchstart', tryPlayVideo);
    //             this.on('ended', function () {
    //                 videojs.log('Awww...over so soon?!');
    //             });
    //         },
    //     );
    // });

    // onUnmounted(() => {
    //     (player as Ref<Player>).value.dispose();
    // });
</script>
